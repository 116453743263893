/* iPad and Larger */
@media only screen and (min-device-width:375px){
	canvas{
		font-family: sans-serif;
		text-align: center;
		height: 100%;
		background: #222;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.contact {
		height: 100vh;
		width: 100vw;
		display: flex;
		flex-direction: column;
		color: black;

	}

	.contact-wrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		z-index: 2;
	}

	.contact-wrap > *{
		display: flex;
		justify-content: center;
	}

	.contact-form {
		display: flex;
		flex-direction: column;
		width: 40%;
		margin-top: 10%;
		justify-content: center;
		align-items: center;
	}

	.contact-form > *{
		margin: 10px;
		width: 100%;
		color: #ffffff;
	}
	.contact-from > p {
		font-size: 1.5em;
	}

	#contact-message {
		height: 250px;
	}

	#contact-alert {
		margin-top: 20%;
		position: absolute;
		bottom: 0;
	}

}

/* Phones  */
@media only screen and (max-device-width:375px){
	.contact {
		height: 100vh;
		width: 100vw;
		background-image: linear-gradient(white, grey);
		display: flex;
		flex-direction: column;
		color: black;
	}

	.contact-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.contact-wrap > * {
		display: flex;
		justify-content: center;
	}

	.contact-form {
		display: flex;
		flex-direction: column;
		width: 60%;
		margin-top: 10vh;
		justify-content: center;
		align-items: center;
	}

	.contact-form > *{
		margin: 10px;
		width: 100%;
	}

	#contact-message {
		height: 250px;
	}

	#contact-alert {
		margin-top: 20%;
		position: absolute;
		bottom: 0;
	}

}
