/* iPad and Larger */
@media only screen and (min-device-width:375px){
	text {
		stroke: #FFFFFF;
		fill: #FFFFFF;
	}
	.tree-wrapper{
		width: 100vw;
		height: 100vh;
		color: #FFFFFF;
	}
	.nodeNameBase{
		stroke: #FFFFFF !important;
	}
	.nodeAttributesBase{
		stroke: #FFFFFF;
	
	}

	.technologies-page{
		background-color: #222222;
		height: 100vh;
		width: 100vw;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.tech-carousel{
		background-color: grey;
		height: 100vh;
	}
	.carousel-caption > h4{
		font-weight: bold;
		font-size: 36px;
	}
	.item.active > h3 {
		color: #FFFFFF;
	}
	.item.active.left > h3 {
		color: #FFFFFF;
	}
	.item.next.left > h3 {
		color: #FFFFFF;
	}
	.item.active.right > h3 {
		color: #FFFFFF;
	}
	.item.prev.right > h3 {
		color: #FFFFFF;
	}
	.item.active.left {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.item.next.left {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.item.active.right {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.item.prev.right {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.item.active {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
