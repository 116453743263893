body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
	height: 200px;
	width: 200px;
}

/* Navbar Buttons */
.navbar-inverse .navbar-nav>li>a:hover {
	color: #FFFFF9;
}

a {
	color: #9d9d9d;
}

a:hover {
	color: #FFFFF9;
	text-decoration: none;
}


/* ----------------------------------------------
 * Generated by Animista on 2019-1-11 10:10:3
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide-in-left {
	-webkit-animation: slide-in-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
	        animation: slide-in-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
}

.slide-in-right {
	-webkit-animation: slide-in-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
	        animation: slide-in-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
}
.fade-in {
	-webkit-animation: fade-in 3.0s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.5s both;
	        animation: fade-in 3.0s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.5s both;
}

/****************************************************************************************/
/* iPad and Larger */
canvas{
	fontFamily: sans-serif;
  textAlign: center;
  height: 100%;
  background: #222;
  display: flex;
  justifyContent: center;
  alignItems: center;
	position: relative;
}
.jumbotron{
	width: 100%;
	position: absolute;
	z-index: 2;
}
.jumbotron > h1, h3, h4, h5{
	color: #FFFFFF;
}

.jumbowrap {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (min-device-width:375px){
	.main{
		height: 100vh;
		width: 100vw;
		/* background-image: url('https://images.unsplash.com/photo-1492541737557-dfd592c6e42d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80'); */
		display: flex;
		flex-direction: column;
		align-items: center;
		color: black;

	}

	.main-wrap {
		display: flex;
		flex-direction: column;
		background-color: rgba(255,255,255,0);
		height: 80vh;
		width: 30%;
		align-items: center;
		justify-content: center;
	}

	.main-wrap > h3 {
		margin-bottom: 10%;
	}
	.main-wrap > h5 {
		text-decoration: none;
		color: #FFFFFF;
		font-size: 1.5em;
	}
	.main-wrap > h5:hover {
		color: #40403F;
		cursor: pointer;
	}
}

/* Phones  */
@media only screen and (max-device-width:375px){
	.main{
		height: 100vh;
		width: 100vw;
		background-image: none;
		display: flex;
		flex-direction: column;
		color: #FFFFF9;
		text-shadow: 1px 1px #FFFFFF;

	}

	.main-wrap {
		display: flex;
		width: 100%;
		flex-direction: column;
	}
	.main-wrap > *{
		display: flex;
		justify-content: center;
	}

	.main > h5 {
		text-decoration: none;
		color: #FFFFF9;
		justify-content: center;
		display: flex;
		margin-top: 30%;
	}
	.main > h5:hover {
		color: #40403F;
		cursor: pointer;
	}

}

/* iPad and Larger */
@media only screen and (min-device-width:375px){
	text {
		stroke: #FFFFFF;
		fill: #FFFFFF;
	}
	.tree-wrapper{
		width: 100vw;
		height: 100vh;
		color: #FFFFFF;
	}
	.nodeNameBase{
		stroke: #FFFFFF !important;
	}
	.nodeAttributesBase{
		stroke: #FFFFFF;
	
	}

	.technologies-page{
		background-color: #222222;
		height: 100vh;
		width: 100vw;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.tech-carousel{
		background-color: grey;
		height: 100vh;
	}
	.carousel-caption > h4{
		font-weight: bold;
		font-size: 36px;
	}
	.item.active > h3 {
		color: #FFFFFF;
	}
	.item.active.left > h3 {
		color: #FFFFFF;
	}
	.item.next.left > h3 {
		color: #FFFFFF;
	}
	.item.active.right > h3 {
		color: #FFFFFF;
	}
	.item.prev.right > h3 {
		color: #FFFFFF;
	}
	.item.active.left {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.item.next.left {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.item.active.right {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.item.prev.right {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.item.active {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

/* iPad and Larger */
@media only screen and (min-device-width:375px){
	canvas{
		font-family: sans-serif;
		text-align: center;
		height: 100%;
		background: #222;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.contact {
		height: 100vh;
		width: 100vw;
		display: flex;
		flex-direction: column;
		color: black;

	}

	.contact-wrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		z-index: 2;
	}

	.contact-wrap > *{
		display: flex;
		justify-content: center;
	}

	.contact-form {
		display: flex;
		flex-direction: column;
		width: 40%;
		margin-top: 10%;
		justify-content: center;
		align-items: center;
	}

	.contact-form > *{
		margin: 10px;
		width: 100%;
		color: #ffffff;
	}
	.contact-from > p {
		font-size: 1.5em;
	}

	#contact-message {
		height: 250px;
	}

	#contact-alert {
		margin-top: 20%;
		position: absolute;
		bottom: 0;
	}

}

/* Phones  */
@media only screen and (max-device-width:375px){
	.contact {
		height: 100vh;
		width: 100vw;
		background-image: -webkit-linear-gradient(white, grey);
		background-image: linear-gradient(white, grey);
		display: flex;
		flex-direction: column;
		color: black;
	}

	.contact-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.contact-wrap > * {
		display: flex;
		justify-content: center;
	}

	.contact-form {
		display: flex;
		flex-direction: column;
		width: 60%;
		margin-top: 10vh;
		justify-content: center;
		align-items: center;
	}

	.contact-form > *{
		margin: 10px;
		width: 100%;
	}

	#contact-message {
		height: 250px;
	}

	#contact-alert {
		margin-top: 20%;
		position: absolute;
		bottom: 0;
	}

}

/* iPad and Larger */
@media only screen and (min-device-width:375px){
	canvas{
		font-family: sans-serif;
		text-align: center;
		height: 100%;
		background: #222;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.samples {
		height: 100vh;
		width: 100vw;
		display: flex;
		color: #ffffff;
		align-items:center;
		justify-content: center;
		overflow: scroll;
	}
	
	.samples-wrapper {
		position: absolute;
		z-index: 2;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100vh;
		overflow: scroll;
	}

	.sample {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 600px;
		text-align: center;
		justify-content: center;
		align-items: center;
		margin: 25px 25px 25px 10%;
	}

	.sample-last{
		font-size: 2em;
		width: 100%;
		text-align: center;
		justify-content: center;
		align-items: center;
		margin: 0 25px 25px 10%;
	}
	.sample-last > p > a{
		color: #FFFFF9;
	}
	.sample-last > p > a:hover{
		color: black;
	}

	.sample-link{
		color: #FFFFF9;
		margin-right: 10px;
	}

	.sample-link:hover{
		color: black;
		cursor: pointer;
	}

	.sample > img{
		height: 400px;
		width: 600px;
	}

	.sample > p {
		margin: 10px 0 10px 0;
		font-size: 24px;
		padding: 30px;
		width: 40%;
	}

}

/* Phones  */
@media only screen and (max-device-width:375px){
	.samples {
		height: 100vh;
		width: 100vw;
		background-image: -webkit-linear-gradient(white, grey);
		background-image: linear-gradient(white, grey);
		display: flex;
		flex-direction: column;
		color: #FFFFF9;
		align-items:center;
		justify-content: center;
		overflow: scroll;
	}

	.samples-wrapper {
		margin: 15% 13px 0 0;
		align-items: center;
		justify-content: center;
		width: 370px;
		height: 100vh;

	}

	.sample {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 350px;
		text-align: center;
		justify-content: center;
		align-items: center;
		margin-bottom: 25px;
	}

	.sample > img{
		height: 250px;
		width: 370px;
	}

	.sample > p {
		margin: 10px 0 10px 0;
		font-size: 16px;
		padding: 0;
		width: 100%;
	}

}

