
/* ----------------------------------------------
 * Generated by Animista on 2019-1-11 10:10:3
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide-in-left {
	-webkit-animation: slide-in-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
	        animation: slide-in-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
}

.slide-in-right {
	-webkit-animation: slide-in-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
	        animation: slide-in-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
}
.fade-in {
	-webkit-animation: fade-in 3.0s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.5s both;
	        animation: fade-in 3.0s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.5s both;
}

/****************************************************************************************/
/* iPad and Larger */
canvas{
	fontFamily: sans-serif;
  textAlign: center;
  height: 100%;
  background: #222;
  display: flex;
  justifyContent: center;
  alignItems: center;
	position: relative;
}
.jumbotron{
	width: 100%;
	position: absolute;
	z-index: 2;
}
.jumbotron > h1, h3, h4, h5{
	color: #FFFFFF;
}

.jumbowrap {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (min-device-width:375px){
	.main{
		height: 100vh;
		width: 100vw;
		/* background-image: url('https://images.unsplash.com/photo-1492541737557-dfd592c6e42d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80'); */
		display: flex;
		flex-direction: column;
		align-items: center;
		color: black;

	}

	.main-wrap {
		display: flex;
		flex-direction: column;
		background-color: rgba(255,255,255,0);
		height: 80vh;
		width: 30%;
		align-items: center;
		justify-content: center;
	}

	.main-wrap > h3 {
		margin-bottom: 10%;
	}
	.main-wrap > h5 {
		text-decoration: none;
		color: #FFFFFF;
		font-size: 1.5em;
	}
	.main-wrap > h5:hover {
		color: #40403F;
		cursor: pointer;
	}
}

/* Phones  */
@media only screen and (max-device-width:375px){
	.main{
		height: 100vh;
		width: 100vw;
		background-image: none;
		display: flex;
		flex-direction: column;
		color: #FFFFF9;
		text-shadow: 1px 1px #FFFFFF;

	}

	.main-wrap {
		display: flex;
		width: 100%;
		flex-direction: column;
	}
	.main-wrap > *{
		display: flex;
		justify-content: center;
	}

	.main > h5 {
		text-decoration: none;
		color: #FFFFF9;
		justify-content: center;
		display: flex;
		margin-top: 30%;
	}
	.main > h5:hover {
		color: #40403F;
		cursor: pointer;
	}

}
