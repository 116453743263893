/* iPad and Larger */
@media only screen and (min-device-width:375px){
	canvas{
		font-family: sans-serif;
		text-align: center;
		height: 100%;
		background: #222;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.samples {
		height: 100vh;
		width: 100vw;
		display: flex;
		color: #ffffff;
		align-items:center;
		justify-content: center;
		overflow: scroll;
	}
	
	.samples-wrapper {
		position: absolute;
		z-index: 2;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100vh;
		overflow: scroll;
	}

	.sample {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 600px;
		text-align: center;
		justify-content: center;
		align-items: center;
		margin: 25px 25px 25px 10%;
	}

	.sample-last{
		font-size: 2em;
		width: 100%;
		text-align: center;
		justify-content: center;
		align-items: center;
		margin: 0 25px 25px 10%;
	}
	.sample-last > p > a{
		color: #FFFFF9;
	}
	.sample-last > p > a:hover{
		color: black;
	}

	.sample-link{
		color: #FFFFF9;
		margin-right: 10px;
	}

	.sample-link:hover{
		color: black;
		cursor: pointer;
	}

	.sample > img{
		height: 400px;
		width: 600px;
	}

	.sample > p {
		margin: 10px 0 10px 0;
		font-size: 24px;
		padding: 30px;
		width: 40%;
	}

}

/* Phones  */
@media only screen and (max-device-width:375px){
	.samples {
		height: 100vh;
		width: 100vw;
		background-image: linear-gradient(white, grey);
		display: flex;
		flex-direction: column;
		color: #FFFFF9;
		align-items:center;
		justify-content: center;
		overflow: scroll;
	}

	.samples-wrapper {
		margin: 15% 13px 0 0;
		align-items: center;
		justify-content: center;
		width: 370px;
		height: 100vh;

	}

	.sample {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 350px;
		text-align: center;
		justify-content: center;
		align-items: center;
		margin-bottom: 25px;
	}

	.sample > img{
		height: 250px;
		width: 370px;
	}

	.sample > p {
		margin: 10px 0 10px 0;
		font-size: 16px;
		padding: 0;
		width: 100%;
	}

}
